<template>
  <v-app>
    <v-card flat>
      <v-toolbar
        color="success"
        dark
        extended
        flat
      >
        <v-row
          align="center"
        >
          <v-col cols="4"></v-col>
          <v-col cols="4" class="text-center display-2">
            <b>Faça aqui o download do seu recibo</b>
          </v-col>
          <v-col
            cols="4"
            class="text-right"
          >
            <template v-if="$store.state.contact.token">
              Bem-vindo <b>{{ $store.state.contact.contact.name }}</b>!

              <v-btn
                class="ml-2"
                small
                outlined
                v-text="'Sair'"
                @click="logout()"
              />
            </template>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-card
        class="mx-auto"
        max-width="700"
        style="margin-top: -49px;"
      >
        <template slot="progress">
          <v-progress-linear color="secondary" indeterminate />
        </template>
        <router-view />
      </v-card>
    </v-card>

    <core-snackbar />
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
    },

    data: () => ({ }),

    methods: {
      logout () {
        this.$store.dispatch('logoutContact')
        this.$router.push(`/download-recibos`)
      },

    }
  }
</script>
